import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
// require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';
import './lib/slick';

$(document).foundation();

// sticky IE対応
var elem = document.querySelectorAll('.sticky');
Stickyfill.add(elem);

$(function() {
  var getDevice = (function(){
    var ua = navigator.userAgent;
    if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
      return 'sp';
    }else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
      return 'tab';
    }else{
      return 'other';
    }
  })();
  if( getDevice !== 'other' ){
    //PC以外は以下のclassを実行
    $(".js_reveal").css("visibility", "visible");
    $(".js_revealHead").css("visibility", "visible");
  }

  // スクロールするとフェードイン
  ScrollReveal().reveal(".js_reveal",{
    duration: 2000,
    scale: 1,
    distance: '20px',
    mobile: false
  });
  ScrollReveal().reveal(".js_revealHead",{
    duration: 2000,
    scale: 1,
    mobile: false
  });





  // スライダー
  // var bool = [1, -1];
  // $('.js_commentSlider').html(
  //   $('.js_commentSliderCard').sort(function(a, b) {
  //     return bool[Math.floor(Math.random() * bool.length)];
  //   })
  // );
  $('.js_commentSlider').slick({
    fade: true,
    arrows: false,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 6000
  });

  $('.js_storySlider').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          centerPadding: '30px',
          autoplaySpeed: 4000,
          speed:1000
        }
      }
    ]
  });

  $('.js_movieSlider').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '30px',
          autoplaySpeed: 4000,
          speed:1000
        }
      }
    ]
  });

  // スクロールしたらヘッダー背景追加
  $(window).scroll(function () {
    if ($(this).scrollTop() > 80) {
      $(".mainContents__jesus").addClass("is_scroll");
    } else {
      $(".mainContents__jesus").removeClass("is_scroll");
    }
  });

  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $(".header").addClass("is_scroll");
    } else {
      $(".header").removeClass("is_scroll");
    }
  });


  // 解説のボタンクリックでタブの先頭にスクロール
  $('.js_scroolButton').on('click', function(){
    $('html,body').animate({scrollTop: $(".js_tab").offset().top - 80}, 500);
  });



  $('.js_revealHead').on('change.zf.tabs', function (event, tab)  {
    if(tab.index() === 1) {
      $('.js_revealHead').addClass("active");
    } else {
      $('.js_revealHead').removeClass("active");
    }
  });
  if(location.hash === "#commentary_02") {
    $('.js_revealHead').addClass("active");
  } else {
    $('.js_revealHead').removeClass("active");
  }

});


$('#menuModal').on('open.zf.reveal', function() {
  $(".js_accordionContents").hide();
  $(".js_accordionButton").on("click",function() {
    $(".js_accordionContents").slideToggle();
    $(this).toggleClass("is_active");
  });
});



